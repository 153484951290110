import { ReactNode } from 'react';
import { StyledContentfulTable, StyledContentfulTableProps, StyledTableWrapper } from './ContentfulTable.styled';

interface ContentfulTableProps extends StyledContentfulTableProps {
  children: ReactNode;
  className?: string;
}

const ContentfulTable = ({ children, ...props }: ContentfulTableProps) => {
  return (
    <StyledTableWrapper>
      <StyledContentfulTable {...props}>{children}</StyledContentfulTable>
    </StyledTableWrapper>
  );
};
export default ContentfulTable;
