import { BREAKPOINTS } from 'constants/breakpoints';
import styled, { css } from 'styled-components';
import { breakpointUp, color, spacer } from 'theme';

export const StyledTableWrapper = styled.div`
  overflow-x: auto;
  margin-bottom: ${spacer(100)};
`;

export interface StyledContentfulTableProps {
  amountOfColumns: number;
  enableTableHeader?: boolean;
}

export const StyledContentfulTable = styled.table<StyledContentfulTableProps>`
  text-align: center;
  overflow-x: scroll;

  th,
  td {
    padding: ${spacer(50)};
    min-width: 170px;

    ${breakpointUp(
      BREAKPOINTS.MD,
      css`
        min-width: 250px;
      `
    )};
  }

  ${({ enableTableHeader }) =>
    enableTableHeader
      ? css`
          thead {
            tr {
              > *:first-child {
                min-width: ${spacer(1000)};
              }
            }
          }

          th {
            border-bottom: 1px solid ${color('black')};
          }
          tbody tr:nth-child(2n) {
            background-color: ${color('primary-transparent-200')};
          }

          tr {
            td,
            th {
              &:first-child {
                border-right: 1px solid ${color('black')};
                text-align: left;
              }
            }
          }
        `
      : css`
          tbody tr:nth-child(2n-1) {
            background-color: ${color('primary-transparent-200')};
          }
        `}

  ${({ amountOfColumns }) =>
    amountOfColumns > 4 &&
    css`
      width: 100%;
    `}
`;
