import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES, Text } from '@contentful/rich-text-types';
import { Children, cloneElement, ReactElement, ReactNode } from 'react';
import Link from '../../Link/Link';
import ContentfulPageLink from '../ContentfulFields/ContentfulPageLink/ContentfulPageLink';
import ContentfulTable from '../ContentfulFields/ContentfulTable/ContentfulTable';
import { CALL_TO_ACTION_TYPES, CONTENTFUL_PAGES, CONTENTFUL_TYPES } from '../ContentfulFields/types';
import ContentfulModelMapper from '../ContentfulModelMapper/ContentfulModelMapper';
import {
  StyledH1,
  StyledImage,
  StyledOrderedList,
  StyledParagraph,
  StyledUnorderedList,
} from './ContentfulFieldRenderer.styled';

const removeBottomMargin = (children: ReactNode) =>
  Children.map(children, (child) => cloneElement(child as ReactElement, { marginBottom: 0 }));

interface ContentfulFieldRendererProps {
  additionalProps?: unknown;
  centerContent?: boolean;
  className?: string;
  richTextContent: Document;
}

const ContentfulFieldRenderer = ({
  additionalProps,
  centerContent,
  className,
  richTextContent,
}: ContentfulFieldRendererProps) => {
  const generalStyling = className;
  const headingStyling = generalStyling;
  const paragraphProps = { center: centerContent, className: generalStyling };
  const headingProps = { center: centerContent };

  // return values of the blocks below have been put in a const before returning to satisfy the eslint-disable react/display-name rule
  const options: Options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => {
        const p = (
          <StyledParagraph {...paragraphProps} type="p">
            {children}
          </StyledParagraph>
        );
        return p;
      },
      [BLOCKS.OL_LIST]: (_, children) => {
        const ol = (
          <StyledOrderedList {...paragraphProps} type="ol">
            {children}
          </StyledOrderedList>
        );

        return ol;
      },
      [BLOCKS.UL_LIST]: (_, children) => {
        const ol = (
          <StyledUnorderedList {...paragraphProps} type="ul">
            {children}
          </StyledUnorderedList>
        );

        return ol;
      },
      [BLOCKS.HEADING_1]: (_, children) => {
        const h1 = (
          <StyledH1 {...headingProps} className={`${headingStyling} h1`} type="h1">
            {children}
          </StyledH1>
        );
        return h1;
      },
      [BLOCKS.HEADING_2]: (_, children) => {
        const h2 = (
          <StyledParagraph {...headingProps} className={`${headingStyling} h2`} type="h2">
            {children}
          </StyledParagraph>
        );
        return h2;
      },
      [BLOCKS.HEADING_3]: (_, children) => {
        const h3 = (
          <StyledParagraph {...headingProps} className={`${headingStyling} h3`} type="h3">
            {children}
          </StyledParagraph>
        );
        return h3;
      },
      [BLOCKS.HEADING_4]: (_, children) => {
        const h4 = (
          <StyledParagraph {...headingProps} className={`${headingStyling} h4`} type="h4">
            {children}
          </StyledParagraph>
        );
        return h4;
      },
      [BLOCKS.HEADING_5]: (_, children) => {
        const h5 = (
          <StyledParagraph {...headingProps} className={`${headingStyling} h5`} type="h5">
            {children}
          </StyledParagraph>
        );
        return h5;
      },
      [BLOCKS.HEADING_6]: (_, children) => {
        const h6 = (
          <StyledParagraph {...headingProps} className={`${headingStyling} h6`} type="h6">
            {children}
          </StyledParagraph>
        );
        return h6;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { data } = node;
        const { file, title } = data.target.fields;
        if (!file) return null;
        const image = <StyledImage alt={title} className={`${generalStyling} image`} src={file.url} />;
        return image;
      },
      [INLINES.HYPERLINK]: (node) => {
        const { content, data } = node;

        const copy = (content as Text[]).map(({ value }) => value)?.join(' ');
        const link = data?.uri;
        const isExternal = link.includes('http');

        const hyperlink = (
          <Link className={`${generalStyling} link`} newTab={!!isExternal} url={link}>
            {copy}
          </Link>
        );

        return hyperlink;
      },
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        const { content, data } = node;
        const contentModel = data?.target?.sys?.contentType?.sys?.id;

        if (CONTENTFUL_PAGES.includes(contentModel)) {
          const copy = (content as Text[]).map(({ value }) => value)?.join(' ');

          const pageLink = (
            <ContentfulPageLink
              hideIfInvalid
              label={copy}
              link={data?.target}
              type={CALL_TO_ACTION_TYPES.LINK}
              iconComponent={undefined}
              iconPosition={undefined}
            />
          );

          return pageLink;
        }

        const component = <ContentfulModelMapper content={data.target} />;
        return component;
      },
      ['table']: (node, children) => {
        const [firstRow, ...rest] = children as ReactNode[];

        const getFirstNode = () => {
          if (node?.content?.[0]?.nodeType === 'table-row') {
            return node?.content?.[0]?.content;
          }
        };

        const firstNode = getFirstNode();
        const tableHeaderEnabled = firstNode?.[0]?.nodeType === 'table-header-cell';
        const amountOfColumns = firstNode?.length ?? 0;

        const table = (
          <ContentfulTable className="table" enableTableHeader={tableHeaderEnabled} amountOfColumns={amountOfColumns}>
            <thead>{firstRow}</thead>
            <tbody>{rest}</tbody>
          </ContentfulTable>
        );
        return table;
      },
      ['table-cell']: (_, children) => {
        const cell = <td className="table-cell">{removeBottomMargin(children)}</td>;
        return cell;
      },
      ['table-header-cell']: (_, children) => {
        const row = <th className="table-header-cell">{removeBottomMargin(children)}</th>;
        return row;
      },
      ['table-row']: (_, children) => {
        const row = <tr className="table-row">{children}</tr>;
        return row;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const { data } = node;

        if (!data?.target?.fields) {
          // Return if there is an unpublished embedded entry inside the rich text
          return null;
        }

        const contentType = data.target.sys.contentType.sys.id;

        const getEmbeddedEntry = () => {
          if (contentType === CONTENTFUL_TYPES.VIDEO) {
            return (
              <div className={`grid lg:grid-cols-4 ${generalStyling} embedded-entry`}>
                <div className="lg:col-span-2 lg:col-start-2">
                  <ContentfulModelMapper content={data.target} />
                </div>
              </div>
            );
          }

          return (
            <ContentfulModelMapper
              additionalProps={additionalProps}
              className={`${contentType === CONTENTFUL_TYPES.BANNER ? 'contentful-template-banner' : ''} ${className}`}
              content={data.target}
            />
          );
        };

        const embeddedEntry = getEmbeddedEntry();
        return embeddedEntry;
      },
    },

    // Replace instances of \n produced by Shift + Enter with <br/> React elements
    renderText: (text) =>
      text
        .split('\n')
        .reduce(
          (children: ReactNode[], textSegment, index) => [...children, index > 0 && <br key={index} />, textSegment],
          [],
        ),
  };

  return <>{richTextContent?.content.length > 0 && documentToReactComponents(richTextContent, options)}</>;
};

export default ContentfulFieldRenderer;
