import styled, { css } from 'styled-components';
import { BREAKPOINTS } from '../../../constants/breakpoints';
import { breakpointDown, breakpointUp, fontSize, spacer } from '../../../theme/functions';
import Image from '../../Image/Image';
import Typography, { TypographyProps } from '../../Typography/Typography';

const paragraphStyling = css`
  ${breakpointUp(
    BREAKPOINTS.SM,
    css`
      text-align: center;
      margin-left: 20%;
      margin-right: 20%;
    `,
  )};
`;

interface StyledTypographyProps extends TypographyProps {
  $center?: boolean;
}

export const StyledParagraph = styled(Typography)<StyledTypographyProps>`
  ${({ $center }) => $center && paragraphStyling}
`;
export const StyledOrderedList = styled(Typography)<StyledTypographyProps>`
  margin-left: ${spacer(100)};
  list-style: decimal;

  ${({ $center }) => $center && paragraphStyling};

  ol {
    list-style: upper-alpha;

    ol {
      list-style: lower-roman;

      ol {
        list-style: lower-alpha;
      }
    }
  }
`;

export const StyledUnorderedList = styled(Typography)<StyledTypographyProps>`
  margin-left: ${spacer(100)};
  list-style: disc;

  ${({ $center }) => $center && paragraphStyling};

  ul {
    list-style: circle;

    ul {
      list-style: square;
    }
  }
`;

export const StyledH1 = styled(Typography)<StyledTypographyProps>`
  ${({ $center }) => $center && paragraphStyling}

  ${breakpointDown(
    BREAKPOINTS.SM,
    css`
      font-size: ${fontSize(150)};
    `,
  )};
`;

export const StyledImage = styled(Image)`
  display: block;
  margin: 0px auto;
  max-width: 100%;
`;
